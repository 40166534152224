.text-cent-section {
    text-align: center;
}
  
.text-cent-section p {
    font-size: 12px;
    margin: 10px 0;
}

.text-cent-section a {
    display: block; /* Make the link a block element to control height */
    font-weight: 400;
    font-size: 11px;
    text-decoration-line: underline;
    color: #000000;
}
  