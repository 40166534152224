.steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    padding-top: 8px;
}
  
.step-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.step-number {
    font-size: 12px;
}

.step-prog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 8px 0px 4px;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.step-prog .step-icon {
    display: flex;
    align-items: center; /* Center icon vertically */
    margin-bottom: 8px; /* Space between icon and the line */
}

.step-prog .step-icon svg {
    width: 18px; /* Icon width */
    height: 18px; /* Icon height */
}

.step-prog .step-line {
    width: 1px; /* Thickness of the vertical line */
    background-color: #A8A8A8; /* Color of the vertical line */
    flex-grow: 1; /* Allow the vertical line to fill available space */
}

.step-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    /* Inside auto layout */
    flex: none;
    flex-shrink: 1;
    width: 100%;
}

.step-block span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    text-transform: uppercase;
    color: #000000;
}

.substep-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0px;
    gap: 5px;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
  
.substep-block {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
  
.substep-block img {
    width: 75px;
    height: 45px;
    border-radius: 6px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.substep-block p {
    font-weight: 400;
    font-size: 9px;
    line-height: 13px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}