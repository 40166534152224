/* Import Jost font */
/* @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  background-color: #fff;
  color: rgb(12,12,12);
}

/* Layout styles */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 14px;
  min-height: 100vh;
  max-width: 480px;
  margin: 0 auto;
}

/* Header Styles */
header {
  background-color: #ffffff;
  border-bottom: 2px solid #eaeaea;
}

.header-thumbnail img {
  border: none;
}

/* Typography */
h1, h2, h3 {
  font-family: 'Poppins','sans-serif';
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
  color: #000000;
}

h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 12px;
}

h5, h6 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  color: #000000;
}

p {
  margin: 0;
  font-size: 16px;
  line-height: 1.6;
}

strong {
  font-weight: 500;
}

/* Links */
a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

.content-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

/* stlying non mobile */
.mobile-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Full viewport height */
  text-align: center; /* Center text */
  gap: 10px;
}

.welcome-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Full viewport height */
  text-align: center; /* Center text */
  gap: 10px;
}

.welcome-message h1 {
  font-weight: 400;
  font-size: 14.4;
}

.welcome-message p {
  font-style: italic;
  font-weight: 300;
  font-size: 13.4;
  color: rgb(67,67,67);
}

.welcome-message span{
  color: rgb(120,120,120);
}