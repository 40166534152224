.header-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 28px;
}

.header-logo img {
    max-height: 65px;
    max-width: 180px;
    width: auto;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 10px;
}

.header-thumbnail {
    margin: 28px 0;
}

.header-thumbnail img {
    width: 220px;
    height: auto;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 10px;
}
  
.header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 14px;
    gap: 50px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.header-title {
    text-align: center;
    font-family: 'Poppins';
}

.header-title > h1 {
    font-weight: 500;
    font-size: 24.2px;
    line-height: 24.2px;
    margin-bottom: 7px;
}

.header-split-bar {
    width: 145px;            /* Set the width to 200px */
    height: 2px;             /* Set the height (thickness) of the line */
    background-color: #9B9A99; /* Set the color of the line */
    margin: 24px auto 0 auto;         /* Optional: Adds some vertical spacing around the line */
}

.header-features {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 16px;
    background-color: #F5F5F5;
    border-radius: 8px;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    
}

.header-features h2 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 14.4px;
    line-height: 14.4px;
    letter-spacing: 0.02em;
    margin-bottom: 20px;
}

.header-title > span {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 13.4px;
    color: rgb(67,67,67);
    line-height: 20px;
}

.features-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
  
.features-list .feature {
    margin: 4px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}

.features-list .feature div {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13.4px;
    line-height: 22px;
    color: rgb(67,67,67);
}

.features-list .feature .feature-title {
    min-width: 140px;
}

.features-list .feature .feature-value {
    text-align: right;
}


.header-bench {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    gap: 20px;
    flex-grow: 0;
}

.header-bench .icon-block {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 0px;
    gap: 10px;
    /* Inside auto layout */
    align-self: stretch;
    flex-grow: 0;
}

.header-bench .icon-container {
    min-width: 35px;
    display: flex;             /* Enable flexbox layout */
    justify-content: center;   /* Center child horizontally */
    align-items: center;  
}

.header-bench .icon-container svg {
    max-height: 100%;
    max-width: 32px;
}

.header-bench .icon-container span {
    font-size: 17.4px;
    color: #047B42;
}

.header-bench .icon-block p {
    text-align: left;
    font-size: 12.3px;
    line-height: 17px;
}