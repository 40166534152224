.list-section {
    padding: 20px 0;
}
  
.list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    padding: 0 30px;
}
  
.list-container .list-item {
    margin: 3px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    align-self: stretch;
    flex-grow: 0;
}

.list-container .list-item span {
    font-weight: 400;
    font-size: 11px;
    text-align: left;
}
