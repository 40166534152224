.text-cent-section {
    text-align: center;
}

.text-cent-section p {
    font-size: 12px;
    margin: 10px 0;
}

.register-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin-top: 20px;
}

.email-input {
    flex: 1;
    background-color: transparent;
    border: 1px solid black;
    padding: 8px;
    text-align: center;
    font-family: "Poppins";
    color: rgb(27, 27, 27);
    font-size: 12px;
    width: 100%; /* Ensure it uses the parent's flex width */
    border-radius: 0;
    box-sizing: border-box;
}

.email-input:focus {
    outline: None;
}

.register-button {
    flex: 1;
    background-color: rgb(55, 95, 53);
    color: #ffffff;
    border: none;
    padding: 8px;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    border-radius: 2px;
    cursor: pointer;
    width: 100%; /* Ensure it uses the parent's flex width */
    font-family: "Poppins";
}

.register-button:hover {
    background-color: darkgreen;    
}

p.error-message {
    color: red;
    font-size: 12px;
    margin-top: 0px;
    text-align: left;
}

p.success-message {
    color: rgb(95, 95, 95);
    font-family: "Poppins";
    font-weight: 200;
    font-style: italic;
    font-size: 12px;
    margin-top: 18px;
}
