.main-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0px;
    padding: 0 14px;
    gap: 10px;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
  