.story-component {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    padding: 0px;
    gap: 4px;
    background-color: #fff;
    border: 1px solid #585858;
    box-sizing: border-box;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.story-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 0px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    /* UX */
    cursor: pointer;
}

.story-header h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-transform: uppercase;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.story-header span {
    font-size: 14px;
    line-height: 17px;
    margin-left: 4px;
}
  
.story-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px 40px;
    gap: 34px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}