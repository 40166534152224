.footer-component {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 15px 0px 30px;
    border-top: 1px solid #585858;
    /* Inside auto layout */
    align-self: stretch;
    flex-grow: 0;
}

.footer-component span {
    font-family: 'Poppins','sans-serif';
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
}

.footer-component span a {
    text-decoration: underline;
    color: inherit;
}